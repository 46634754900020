import axios from "axios";
import { ElMessage } from 'element-plus';
import { isRef, isProxy } from "vue";
import router from '@/router';
axios.defaults.baseURL = "https://pyend.xiaoshiyan.top/api";
// axios.defaults.baseURL = "https://localhost:6172/api";
axios.defaults.withCredentials = true;
//创建axios实例，请求超时时间为300秒
const instance = axios.create({
  timeout: 300000,
});

function convertToJSON(data) {
  const result = {};
  if (data instanceof FormData) {
    return data
  }

  for (const key in data) {
    if (isRef(data[key])) {
      result[key] = data[key].value
    }
    else if (isProxy(data[key])) {
      result[key] = data[key]
    }
    else {
      result[key] = data[key]
    }
  }
  return result;
}
// 处理err
const handleErr = (err) => {
  console.log(err)
  if (err.response !== undefined) {
    //如果有data.data获取data.data.message否则获取data.message
    let message = err.response.data.data ? err.response.data.data.message : err.response.data.message
    ElMessage.error({
      message: err.response.data.code + ":" + message,
      grouping: true
    })
    if (err.response.data.code == 300) {
      router.replace('/login')
    }
  }
  else {
    ElMessage.error(err)
  }
}
//按照请求类型对axios进行封装
const api = {
  get(url, data, options = {}) {
    return instance.get(url, { params: convertToJSON(data), ...options }).catch((err) => {
      handleErr(err)
    })
  },
  post(url, data, options = {}) {
    return instance.post(url, convertToJSON(data), options).catch((err) => {
      handleErr(err)
    })
  },
}

export { api }